.category-listing{

    &.container{
        padding-top: 0;
        padding-bottom: 0;
    }

    & p{
        margin: 0;
    }

    & .tile {
        padding-bottom: 1.4rem;
    }

    &__item--highlight{
        color: $color-accent-1;
        font-weight: 700;
    }

    & .image-container{
        position: relative;
        max-height: calc(100vh - #{$header-height-mobile});
        @include tabletdesktop{
            max-height: calc(100vh - #{$header-height-tablet-desktop});
        }
    }
}