@font-face {
    font-family: "NewRailAlphabet-light";
    src: url( "~@/assets/bricmate/fonts/NewRailAlphabet-LightWEB.eot");
    src: url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-Light.woff") format("woff"),
    url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-Light.woff2") format("woff2"),
      url("~@/assets/bricmate/fonts/NewRailAlphabet-LightWEB.svg") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "NewRailAlphabet-thin";
    src: url("~@/assets/bricmate/fonts/NewRailAlphabet-OffWhiteWEB.eot");
    src: url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-OffWhite.woff") format("woff"),
       url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-OffWhite.woff2") format("woff2"),
      url("~@/assets/bricmate/fonts/NewRailAlphabet-OffWhiteWEB.svg") format("svg");
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "NewRailAlphabet-bold";
    src: url("~@/assets/bricmate/fonts/NewRailAlphabet-BoldWEB.eot");
    src: url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-Bold.woff") format("woff"),
    url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-Bold.woff2") format("woff2"),
      url("~@/assets/bricmate/fonts/NewRailAlphabet-BoldWEB.svg") format("svg");
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "NewRailAlphabet-medium";
    src: url("~@/assets/bricmate/fonts/NewRailAlphabet-WhiteWEB.eot");
    src: url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-White.woff") format("woff"),
    url("~@/assets/bricmate/fonts/NewRailAlphabetWeb-White.woff2") format("woff2"),
      url("~@/assets/bricmate/fonts/NewRailAlphabet-WhiteWEB.svg") format("svg");
    font-weight: 500;
    font-style: normal; 
  }
  
  