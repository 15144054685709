.menu-list{
    & .menu-list__item{
        border-top: 1px solid black;
        & span{
            font-size: $fontsize-small;
        }
    }

    &__icon{
        position: relative;
        & .icon{
            position: absolute;
            right: 0;
            top: 3px;
            @include icon;

            &--facebook{
                @include icon--facebook;
            }

            &--instagram{
                @include icon--instagram;
            }

            &--pinterest{
                @include icon--pinterest;
            }

        }
    }

    &__item{
        &--bold{
            font-weight: 700;
        }

        &--primary{
            color: $color-accent-1;
        }

        >a{
            cursor: pointer;   
            @include tabletdesktop{
                display: block;
            }
            &:hover{
                text-decoration: none;
            }
        }

        & p {
            margin: 0;
        }

    }

    &--small{
        & li {
            padding-bottom: rem(5px);
            padding-top: rem(5px);
        }
    }

    &--medium{
        & li {
            padding-bottom: 0.5rem;
            padding-top: 0.5rem;
        }
    }

    &--large{
        & li {            
            padding-bottom: 10px;
            padding-top: rem(5px);

            @include tabletdesktop{
                min-height: 60px;
            }
            

        }
    }


}