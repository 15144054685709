//fonts
$font-family-body               : $font-light, 'Times New Roman', Times, serif;

//Colors TODO: Update
$color-black                : #382E2C !default;
$color-white                : #FFFFFF !default;
$color-accent-1             : #FE5000 !default;
$color-accent-2             : #999999 !default;
$color-accent-3             : #fce4e0 !default;
$color-accent-4             : #e7e7e7 !default;
$color-accent-5             : #858585 !default;
$color-accent-6             : #D5D5D5 !default;
$color-accent-7             : #EDE102 !default;
$color-accent-8             : #F97070 !default;
$color-accent-9             : #B9B9B9 !default;
$color-accent-10            : #ff5100 !default;
$color-error                : red !default;
$color-link                 : #184b40 !default;

$color-bg-1                 : #f5f5f5 !default;
$color-background           : #EBEAE9 !default;

// Font-sizes
$fontsize-tiny              : 12px;
$fontsize-xsmall            : 14px;
$fontsize-small             : 18px;
$fontsize-normal            : 20px;
$fontsize-large             : 22px;
$fontsize-xlarge            : 26px;

$fontsize-h4               : 28px;
$fontsize-h3                : 32px;
$fontsize-h2                : 36px;
$fontsize-h1                : 42px;


$max-width : rem(1920px);
$header-height-tablet-desktop: rem(91px);
$header-height-mobile: rem(61px);
$meny-width: rem(312px);
$column-padding: 15px;


$hero-content-min-width: 700px;
$hero-content-max-width: 950px;


// Notice, equals to the total padding and not only these variables
// Content -> content header = 45px desktop and 32px mobile 
$block-content-padding--desktop: 29px;
$block-content-padding--mobile: 24px;

// content header -> next block = 60 px desktop and 47 px mobile
$block-padding--desktop: 44px;
$block-padding--mobile: 29px;

// Image aspects ratios
$image-landscape: (5 / 7) * 100%;  
$image-portrait: (7 / 5) * 100%;
$image-square: (5 / 5) * 100%;
$image-carousel: (9 / 16) * 100%;

$variant-tile-size: 480px;

// Breakpoints
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (min-width: 0px) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin mobiletablet {
  @media (min-width: 0) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tabletdesktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}