.thank-you-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    &--open {
        display: block;
    }

    &__content {
        background-color: #fefefe;
        padding: 15px 0 15px 0;
        border: 1px solid #888;
        width: 80%; /* Could be more or less, depending on screen size */

        @include center-x-y;

        @include desktop{
            width: 50%;
            padding: 20px 0 40px 0;
        }

        > div {
            position: relative;
        }
    }

    &__close-btn {
        @include icon;

        @include icon--close;
        position: absolute;
        right: rem(20px);
        top: 0;

        @include desktop {
            width: rem(30px);
            height: rem(30px);
            right: 1.4375rem;
        }
    }
}
