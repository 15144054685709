.quantity-picker {
  &__container {
    display: flex;
    flex-flow: row;
    padding: 1rem 0;

    > span {
      display: block;
      font-family:$font-family-body ;
      border: 0;
      padding: 0 $line-margin-half;
      line-height: $line-height;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    a {
      display: block;
      padding: 0 calc(#{$line-margin-half} + 2px);
      border-left: 1px solid $border-black;
      border-right: 1px solid $border-black;
      cursor: pointer;
      line-height: $line-height;
      min-width: 15px;
      text-align: center;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      span {
        font-family: $font-bold!important;
      }
    }
  }

  > span {
    display: block;
  }


  &__label {
    display: block;
    line-height: $line-height;
  }

  &__quantity {
    font-family: $font-bold;
    display: block;
    border: 0;
    padding: 0 $line-margin-half;
    min-width: 25px;
    text-align: center;
    transition: 0.2s ease-in-out;
    @include mobiletablet {
      font-size: $font-size-default-mob;
    }
    &.quantity--large {
      min-width: 30px;
    }

    input[type=text]{
      border:none;
      max-width: 40px;
      text-align: center;
      border:1px solid $border-black;
      font-family: $font-bold;
      font-size: $fontsize-xsmall;
      line-height: 1.2;
      outline: none;
      padding: 2px 0;
    }
  }
}
