.search-result{
    &__header{
        padding-top: rem(24px);

        @include desktop {
            padding-top: 7.375rem;
        }

        & h2{
            @include mobile{
                font-size: $fontsize-large;
            }
        }
    }

    &__product{

        & span{
            text-decoration: none;
        }

        > img{
            &:hover{
                opacity: 1;
            }
        }

        & .variant__info{
            .product-category {
                font-weight: 600;
            }

            height: 100%;
            border-bottom: none;
            >div:first-child{
                height: 100%;
                border-bottom: 1px solid $color-black;
            }
        }
    }

    & .variant > .row--modifier{
        border-bottom: none;
    }
}