.interactive-video {
  @include mobiletablet {
    margin: 2rem 0;
  }
  video {
    width: 100%;
    position: absolute;
  }
  &__video-container {
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
      width: 100%;
    }
  }
  &__intro-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    text-align: center;
    opacity: 1;
    transition: opacity ease-out 0.4s;

    h4 {
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding: 0.65rem 1rem;
      margin-bottom: 2rem;
      font-size: 1.375rem;
      font-size: clamp(14px, 1.5714vw, 2rem);
      font-weight: 300;
      line-height: 133%;
      @include mobiletablet {
        font-size: 1.375rem;
      }
    }

    p {
      margin: 0;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 130%;
    }

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    grid-gap: 0 0.5rem;
    .arrow {
      @include icon--arrow-white;
      @include icon;
      transform: rotate(90deg);
      &--right {
        transform: rotate(90deg) scale(-1);
      }
    }
    .cursor {
      @include icon--cursor;
      @include icon;
    }
  }
  &__intro {
    padding: 2rem;
  }
  &__custom-slider {
    margin-top: 1rem;
  }
  .vue-slider {
    &-rail {
      background-color: #a6a6a6;
    }
    &-dot-handle {
      background-color: #ffffff;
      border-radius: 25px;
      border: 2px solid #aaaaaa;
      &:after {
        content: "...";
        position: absolute;
        direction: unset;
        writing-mode: tb;
        left: calc(50% + 4px);
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .vue-slider-process {
      background-color: #000;
    }
    &:hover {
      .vue-slider-process {
        background-color: #000;
      }
      .vue-slider-rail {
        background-color: darken(#a6a6a6, 7%);
      }
    }
  }

  &__open-modal {
    &-btn {
      display: none;
      @include desktop {
        display: flex;
        justify-content: flex-end;
        padding: 16px 0;
        cursor: pointer;

        > span {
          padding-right: 35px;
          position: relative;
        }
      }
    }

    &-icon {
      position: absolute;
      right: 0;

      @include center-y;
      @include icon;
      @include icon--expand;
    }
  }
}

.modal-interactive-video {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 101; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  &--open {
    display: block;
  }

  &__content {
    background-color: $color-background;
    padding: 15px 5px 15px 5px;
    border: 1px solid #888;
    width: 90%; /* Could be more or less, depending on screen size */
    display: flex;
    justify-content: center;
    align-items: center;

    @include center-x-y;

    @include desktop {
      width: 80%;
      padding: 50px 20px 70px 20px;
      height: 90%;
      overflow: auto;
    }

    > div {
      position: relative;
    }
  }

  &__video-container {
    width: 100%;

    @include desktop {
      width: 80%;
      height: 100%;

      & .interactive-video {
        height: 100%;
        &__video-container {
          height: 100%;

          & video {
            height: 100%;
          }

          &:after{
            content: none
          }
        }
      }
    }
  }

  &__close-btn {
    @include icon;

    @include icon--close-thin;
    position: absolute;
    right: rem(10px);
    top: rem(10px);

    @include desktop {
      width: rem(30px);
      height: rem(30px);
      right: rem(32px);
      top: rem(22px);
    }
  }
}
