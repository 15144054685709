.footer {
    background-color: $color-white;
    margin-top: auto;
@media print {
    display: none;
}
    &__contact-info {
        & span {
            display: block;
        }
    }

    & .menu-list:not(.hide-mobile) {
        & a {
            > .icon {
                position: unset;
                margin-top: rem(5px);
                display: inline-block;
            }
            &:not(:first-child) {
                > .icon {
                    margin-left: rem(15px);
                }
            }
        }
    }

    &__logo-container {
        position: relative;

        @include tabletdesktop {
            padding-bottom: unset;
        }
    }

    & .logo {
        position: absolute;
        width: 9rem;
        height: 2rem;
        right: unset;
        left: 0;
        bottom: 0;
        background-size: 98% 100%;
        overflow: visible;

        @include tabletdesktop {
            top: -5px;
            right: #{$column-padding};
            bottom: unset;
            left: unset;
        }

        &.icon--logo {
            @include icon--logo;
        }
    }

    &-row--modifier {
        
        &.row--modifier [class^="col"] {

            @include tablet{
                padding-left: rem(10px);
                padding-right: rem(10px);
            }
            @include desktop{
                padding-left: rem(25px);
                padding-right: rem(25px);
            }
            &:first-child{
                padding-left: 0;
            }
    
            &:nth-last-child(2){
                padding-right: 0;
            }
            

            &.footer__logo-container {
                padding-bottom: 40px;
            }
        }

       
    }

    @include tablet {
        padding: 1.6875rem 0 1.6875rem 0;
    }

    @include desktop {
        padding: rem(43px) 0 rem(102px) 0;
    }
}
