.order-samples{
    padding-top: 3rem;
}

.sample-form {
    &__text-area {
        min-height: 160px;
        border-bottom: 1px solid black;
    }

    &__action-container {
        & label {
            margin-top: 1rem;
        }

        & button {
            display: block;
            padding-top: 1rem;
            color: #000000;
        }
    }
}
