.search {
    display: relative;
    &-bar {
        position: absolute;
        top: -150px;
        left: 0;
        width: 100%;
        height: $header-height-mobile;
        background: white;
        font-size: 26px;
        line-height: 93px;
        transition: top 0.25s ease-in-out 0s;
        z-index: 1000;

        @include tabletdesktop {
            height: $header-height-tablet-desktop;
        }

        &--active {
            top: 0px;
        }
    }

    & form {
        height: 100%;

        > .search__close-btn {
            @include center-y;
            left: rem(10px);
            @include tabletdesktop{
                left: rem(35px);
            }
        }

        & input {
            line-height: rem(34px);
            @include center-y;
            font-size: $fontsize-normal;
            left: rem(45px);
            @include tabletdesktop{
                left: rem(75px);
            }
        }
    }

    &__close {
        &-icon {
            @include icon;
            @include icon--close;
        }
        &-btn {
        }
    }
}

#backgroundOverlay {
    background-color: transparent;
    position: fixed;
    top: $header-height-mobile;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    @include tabletdesktop {
        top: $header-height-tablet-desktop;
    }
}
