.container{
    &--modifier{
        width: 100%;
        padding: 1rem;

        @include desktop{                        
            padding: rem(44px) 5.125rem 0 5.125rem;
        }        
    }

    &--fullscreen{
        width: 100%;
    }

    &--widescreen{
        width: 80%;
    }
}

.row--modifier{
    @include desktop {
        margin: auto;
    }

    & [class^="col"]{
        
        padding: #{$column-padding} 0 0 0;

        @include tabletdesktop{
            padding: 1rem 5px 0 5px;
        }
    }
}