
.image-video__container{
    width: 100%;
    max-width: 1440px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include desktop{
        min-height: 810px;
    }

    &--fullscreen{
        width: 100%;
        max-width: unset;
        @include desktop{
            padding: 0;
        }

        & .img-block{
            width: 100%;

            @include desktop{
                height: calc(100vh - #{$header-height-tablet-desktop}); 
            }
        }

        & .video-block {
            height: calc(100vh - #{$header-height-mobile}); 
            width: 100%;

            @include tabletdesktop{
                height: calc(100vh - #{$header-height-tablet-desktop}); 
            }
        }
    }

    & .img-block {
        /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
        // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        //     url("https://cdn.pixabay.com/photo/2015/07/29/00/08/photographer-865295_960_720.jpg");
    
        /* Set a specific height */
        
        width: 100%;
    
        /* Position and center the image to scale nicely on all screens */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
    
        
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        
    }
    
    //Only on mobile
    & .image-container:after{
        padding-bottom: $image-portrait;
    }

    & .video-block {
        position: relative;        
        width: 100%;
    
        @include desktop{
            min-height: 810px;
        }
           
        .video-container {
            background: #eee;
            height: 100%;
            overflow: hidden;
            padding: 0;
            position: relative;
        }
    
        iframe {
            box-sizing: border-box;
            height: 56.25vw;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            width: 177.77777778vh;
        }
    }
}

