.environmental-marking-table-bricmate {
    margin: auto;
  .environmental-marking-table-bricmate-inner-wrapper {
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 120rem;

    .image-container-environmental {
      position: relative;
      width: 100%;
      height: 550px; 

      @include mobiletablet {
        height: 90vh;
      }
    }
    
    .image-container-environmental img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .overlay-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(0, 0, 0, 0.4); 
      color: white;
      padding: 30px;
      width: 65%;

      @include mobiletablet {
        padding: 25px;
        width: 90%
      }
    }
    
    .overlay-text h1 {
      word-break: keep-all;
      font-size: $font-size-heading;
      text-align: left;
      font-weight: 400;
      line-height: 1;
      @include mobiletablet {
          font-size: $font-size-heading-mob;
      }
    }
    
    .overlay-text p {
      font-size: 1.1rem;
      line-height: 1.5;
      text-align: left;

      @include mobiletablet {
        font-size: 0.9rem;
      }
    }

    .environmental-editor-area {
      max-width: 1110px;
      margin: 0 auto;
    }

  

  }
  
  .rich-text__content {
    margin-bottom: 60px;
    h1 {
      font-size: clamp(1.75rem, 3vw, 2.5rem);
      word-break: break-all;
    }
  }

  .table-wrapper {
    overflow-x: auto;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    
  }

  .table {
    display: table;
    width: 100%;
    border-collapse: collapse;
  }

  .table-header {
    display: table-header-group;
    

    .table-row {
      display: table-row;

      .table-cell {
        display: table-cell;
        font-weight: bold;
        padding: 15px 22.5px;
        background-color: #f1f1f1;
        position: sticky;
        top: 0;
        z-index: 1;
        width: 200px; 
        white-space: nowrap; 
        @media (max-width: 500px) {
          padding: 15px 10px;
        }
      }

      .table-cell-productname {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #f1f1f1;
        white-space: nowrap; 
      }
    }
  }

  .table-body {
    display: table-row-group;

    .table-row {
      display: table-row;

      .table-cell {
        display: table-cell;
        padding: 15px 22.5px;
        border-bottom: 1px solid #dee2e6;
        text-align: left;
        background-color: #ffffff;
        width: 200px; 
        @media (max-width: 500px) {
          padding: 15px 10px;
        }
      }

      .table-cell-productname {
        position: sticky;
        left: 0;
        background-color: #ffffff;
        z-index: 1;
      }

      .environmentalmarking-check-icon {
        width: 16px;
        height: 16px;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAEPQAABD0Byy/GpgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAADPSURBVEiJ1ZW9DcJADEafwwBZIwMwAb+z0LMHNQ1CiGEYgB3YgHSYJheFEDvkyBWx5O6793TyWSeqSsrKktInKRCRXEQuIlIAoKqjNZADN0CBB1Ckgoe+p4Q/gUVSuKr+NwMHvqwzVTAD5mPDwxJnwAl4Absx4UFwbIR6JQ58ZeQ5t8KmZCg8CGbAtU9iwEtg7d64OuxKYuEfz9SR7GPhX3tgSNpdApufn3PHUDzJILi5yYakBLZD4KagQxIFdwUNySEWrqpIWIZUNb0/uV1vJvpt6JtuhMsAAAAASUVORK5CYII=');
        background-size: cover;
        background-repeat: no-repeat;
        margin-left: 5px;
      }
    }

  }

  h2 {
    padding: 5px 0 5px 22.5px;
    margin-bottom: -5px;
    margin-top: 40px;
    color: #382E2C;
    font-size: 1.25rem;
    text-transform: capitalize;
    font-weight: 500;
    border-bottom: solid 1px #382E2C;
  }

  .table-wrapper::-webkit-scrollbar {
    height: 10px;
    margin-top: 30px;
  }

  .table-wrapper::-webkit-scrollbar-track {
    background: #f8f9fa;
    border-radius: 5px;
  }

  .table-wrapper::-webkit-scrollbar-thumb {
    background-color: #FE5000;
    border-radius: 5px;
  }

  .table-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #d14808;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    .table-cell {
      font-size: 0.9rem;
    }
    .table-header .table-cell-productname,
    .table-body .table-cell-productname {
      width: 125x; 
      font-size: 0.9rem;
    }
    
  }
}
