.favourite-page{
    &__find-store-btn{
        text-transform: none;
        font-weight:600;
        cursor:pointer;
        font-size:0.8rem;
    }
}

.architect-samples{
    padding-top: 1rem;
    @include desktop{
        padding-top: 6rem;
    }

    &__action-download{
        &[class^=col]{
            padding-top: rem(30px);
        }
    }

    & .row:nth-child(2){
        @include mobile{
            border-bottom: 1px solid $color-black;
            padding-bottom: rem(31px);
        }

        & [class^="col"]{
            @include tabletdesktop{
                border-bottom: 1px solid $color-black;
            }
        }
    }
}

.favourite{
    &-tile{
        display: inline-block;

        > div{
            border-bottom: 1px solid $color-black;
            padding-bottom: rem(10px);
        }
        &__img{
            height: 350px;
            max-width: 350px;
            width: 100%;
            object-fit: cover;
        }

        &__info{
            padding-top: 1rem;
            >span{
                display: block;
                font-weight: 600;
                &:last-child{
                    padding-top: 0.2rem;
                }
            }

        }
    }
}

