
@mixin center-x{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-y{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-x-y{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* icon */
@mixin icon {
    width: rem(24px);
    height: rem(24px);
    background-repeat: no-repeat;
    background-position: center;
}
@mixin icon--medium {
    width: rem(16px);
    height: rem(16px);
    background-repeat: no-repeat;
    background-position: center;
}
@mixin icon--small {
    width: rem(10px);
    height: rem(10px);
    background-repeat: no-repeat;
    background-position: center;
}

// Add custom icons here
@mixin icon--search {
    background-image: url("~@/assets/bricmate/img/search.svg");
}

@mixin icon--download {
    background-image: url("~@/assets/bricmate/img/download.svg");
}

@mixin icon--download-white {
    background-image: url("~@/assets/bricmate/img/download--white.svg");
}

@mixin icon--close {
    background-image: url("~@/assets/bricmate/img/close.svg");
}

@mixin icon--close-thin {
    background-image: url("~@/assets/bricmate/img/close--thin.svg");
}

@mixin icon--facebook {
    background-image: url("~@/assets/bricmate/img/facebook.svg");
}

@mixin icon--instagram {
    background-image: url("~@/assets/bricmate/img/instagram.svg");
}

@mixin icon--pinterest {
    background-image: url("~@/assets/bricmate/img/pinterest.svg");
}

@mixin icon--arrow {
    background-image: url("~@/assets/bricmate/img/arrow.svg");
}

@mixin icon--expand{
    background-image: url("~@/assets/bricmate/img/expand.svg");
}

@mixin icon--star{
    background-image: url("~@/assets/bricmate/img/star.svg");
}

@mixin icon--star-white{
    background-image: url("~@/assets/bricmate/img/star--white.svg");
}

@mixin icon--star-filled{
    background-image: url("~@/assets/bricmate/img/star-filled.svg");
}

@mixin icon--star-filled-white{
    background-image: url("~@/assets/bricmate/img/star-filled--white.svg");
}

@mixin icon--marker{
    background-image: url("~@/assets/bricmate/img/marker.svg");
}

@mixin icon--link{
    background-image: url("~@/assets/bricmate/img/link.svg");
}

@mixin icon--next{
    stroke: black;
    background-image: url("~@/assets/bricmate/img/next.svg");
}
@mixin icon--cursor{
    background-image: url("~@/assets/bricmate/img/cursor.svg");
}
@mixin icon--arrow-white{
    background-image: url("~@/assets/bricmate/img/arrow--white.svg");
}
@mixin icon--print-white{
    background-image: url("~@/assets/bricmate/img/printer_white.svg");
}
@mixin icon--print{
    background-image: url("~@/assets/bricmate/img/printer_black.svg");
}
@mixin icon--minus{
    background-image: url("~@/assets/bricmate/img/minus.svg");
}
@mixin icon--minus-thin{
    background-image: url("~@/assets/bricmate/img/minus-thin.svg");
}

@mixin icon--plus{
    background-image: url("~@/assets/bricmate/img/plus.svg");
}

@mixin icon--plus-thin{
    background-image: url("~@/assets/bricmate/img/plus-thin.svg");
}

@mixin icon--cart {
    background-image: url("~@/assets/bricmate/img/cart.svg");
}

@mixin icon--logo {
    width: 5rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: center;

    @include tabletdesktop{
        width: 6rem;
        height: 2rem;
    }
}


@mixin icon--menu {
    background-image: url("~@/assets/bricmate/img/menu.svg");
}

@mixin icon--ellipse {
    background-image: url("~@/assets/bricmate/img/ellipse.svg");
}



@mixin full-height {
    height: calc(100vh - #{$header-height});
}