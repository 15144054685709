.Cookie--bricmate {
  background: $color-accent-1;
  color: $color-black;
  padding: 1.250em;
  font-weight: 700;

  .Cookie__button {   
    font-weight: 800;
   
  }
}