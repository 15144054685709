.checkbox-round {
    display: block;
    position: relative;
    margin: rem(14px) 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @include tabletdesktop{
        padding-left: rem(30px);
    }

    &__checkmark{
        position: absolute;
        right: rem(32px);
        @include center-y;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border: 1px solid black;
        border-radius: 50%;

        @include tabletdesktop{
            left: 0;
            right: unset;
        }
    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

    & input:checked ~ .checkbox-round__checkmark{
        &:after{
            content: "";
            @include center-x-y;
            width: 10px;
            height: 10px;
            background-color: black;
            border-radius: 50%;
        }
    }
}

.checkbox-square{
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
    cursor: pointer;
    padding-right: rem(50px);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &__checkmark{
        position: absolute;
        right: 0;
        @include center-y;
        height: 12px;
        width: 12px;
        background-color: transparent;
        border: 1px solid black;

        @include tabletdesktop{
            right: 0;
        }

    }

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & input:checked ~ .checkbox-square__checkmark{
        &:after{
            content: "";
            @include center-x-y;
            width: 10px;
            height: 10px;
            background-color: black;
        }
    }
}
