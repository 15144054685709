.project-listing{
    padding-top: 0;
    padding-bottom: 0;

    &__products{

        
        >::after {
            content: ",";
        }

        >:last-child:after {
            content: none;                
        }

        & li {
            display: inline;                       
            
 
        }
    }

    &__load-more{
        display: flex;
        justify-content: center;
        border-bottom: 1px solid $color-black;
    }

    & .row--modifier {
        & [class^=col].project-listing__load-more{
            padding: 1.6rem 0 1.6rem 0;
        }
    }
}