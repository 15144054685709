.carousel {

    @include desktop{
        max-width: 70%;
        margin: 0 auto;
    }

    &__current-element{
        & img{
            transition: width 2s;
        }
    }

    &__action-bar {
        display: flex;
        justify-content: center;
        padding-top: 0.6rem;

        @include desktop {
            padding-top: 2rem;
        }
    }
}

.card {
    &__image {
        width: 100%;
        

        &.tile {
            border-bottom: none;
            padding: 0;
            position: relative;

            &:hover {
                box-shadow: none;
            }

            & .image-container:after {
                //Calculated with the help of https://ratiobuddy.com/ Dimensions are taken from the sketches
                padding-bottom: $image-landscape;
                position: relative;
            }
        }
    }

    &__zoom{
        &-controls{
            position: absolute;
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);            
            display: flex;
            gap: 1rem;
            justify-content: center;
            padding-left: 10px;
            min-height: 1px;
            
            @include desktop{
                bottom: -49px;
                right: 0;
                left: unset;
                transform: unset; 

            }
            & button{                
                position: relative;
                width: 25px;
                height: 25px;
            }            
        }
        &-in{
            @include icon--medium;
            @include icon--plus;
        }

        &-out{
            @include icon--medium;
            @include icon--minus;
        }
    }
}

.arrow {
    &__btn {
        @include desktop {
            @include icon;
            @include icon--next;
            width: 24px;
            height: 48px;

            &:disabled {
                filter: invert(79%) sepia(71%) saturate(32%) hue-rotate(37deg) brightness(116%) contrast(54%);
            }

            &--prev {
                transform: rotate(180deg);
            }

            &:not(:disabled) {
                &:hover {
                    filter: invert(35%) sepia(100%) saturate(2636%) hue-rotate(1deg) brightness(101%) contrast(105%);
                }
            }
        }
    }
}

.indicator {
    display: inline-flex;
    padding: 0 10px;
    @include desktop {
        padding: 0 40px;
    }

    .dot {
        height: 12px;
        width: 12px;
        margin-left: 7px;
        border: 1px solid $color-black;
        cursor: pointer;
        position: relative;
        align-self: center;

        &:first-child {
            margin-left: 0;
        }
    }

    .dot-selected {
        background-color: $color-black;
        cursor: default;
    }

    .indicator-btn {
        position: absolute;
        left: 0px;
        padding: 0px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: transparent;
        border: none;
    }
}
