.text-image {
    &.container{
        padding-top: 0;
        padding-bottom: 0;
    }

    &__img-container {
        text-align: end;
        margin-top: 1em;
    }

    & .row:nth-child(2){

        @include tabletdesktop{
            padding-top: rem(20px);
        }
    }
}
