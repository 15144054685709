.architects-order-collection {
    &__text-area {
        min-height: rem(176px);
        border-bottom: 1px solid $color-black;

        & textarea {
            height: calc(11rem - 2.56rem);
        }
    }

    &__names {
        &-item {
            border-bottom: 1px solid $color-black;
            padding-top: 1.2rem;
            padding-bottom: rem(3px);
            >.checkbox-square{
                margin-bottom: rem(2px);
                width: 100%;
            }
        }
    }
}
