.link{

    &--action{
        font-weight: 700;
        text-transform: uppercase;
    }

    &--bold{
        font-weight: 700;
    }

    &--underline{
        text-decoration: underline;
    }

    

}
