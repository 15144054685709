.architects-orderings {
    & .tile {
        height: unset;
    }

    &__scroll-to-link{
        &:hover{
            text-decoration: none;
        }
    }

    &__tab {
        
        &-link{
            position: relative;
        }

        &-content {
            padding-top: rem(60px);
            @include desktop{
                padding-top: 1rem;
            }
        }

        &--active {

            & .architects-orderings__tab-icon {                
                width: 1rem;
                height: 1rem;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(-90deg);
                background-image: url('~@/assets/bricmate/img/arrow--thick.svg');         
            }

            & .architects-orderings__tab-link-text{
                padding-left: rem(25px);
                font-weight: 700;
            }
        }
    }
}
