$text-green: #38d430;
$text-red: #ff0000;
$text-black: #000;
$text-grey: #bdbcbc;
$text-dark-grey: #828282;
$text-white: #ffffff;

$background-white: #ffffff;
$background-black: #000;
$background-grey: #bdbcbc;
$background-light-grey2: #e7e7e7;
$background-light-grey: #f2f2f2;
$background-dark-grey: #747476;

$background-toniton-blue: #949ea8;
$background-toniton-yellow: #f0dba4;
$background-toniton-creme: #deceb5;
$background-toniton-peach: #b38271;
$background-toniton-black: #626161;
$background-toniton-green: #9da494;

$border-black: #000;
$border-grey: #bdbcbc;

$body-padding: 55px;
$body-width: 1600px;
$column-width-proc: 12.5%;
$column-gap: 30px;
$column-margin: 15px;
$column-width: 200px;
$header-height: 90px;
$header-z-index: 99999;
$banner-height: calc(1615px - #{$header-height} - #{$body-padding});
$line-margin: 10px;
$line-margin-half: 5px;

$line-height: 1.2;

$font-size-default: 16px;
$font-size-heading: 45px;
$font-size-menu: 18px;
$font-size-mega-menu: 24px;
$font-size-large: 20px;

$font-size-default-mob: 14px;
$font-size-heading-mob: 31px;
$font-size-menu-mob: 16px;

$font-bold: NewRailAlphabet-bold;
$font-light: NewRailAlphabet-light;
$font-icon: NewRailAlphabet-medium;

$background: $background-white;
$text: $text-black;
@mixin btn-default($background, $text) {
  padding: $line-margin;
  text-align: right;
  width: 100%;
  background: $background;
  color: $text;
  border: 1px solid $text;
  font-size: $font-size-default;
  -webkit-appearance: none;
  border-radius: 0;
  cursor: pointer;
}

$thumb-image-size--mobile: 55px;
$thumb-image-size--desktop: 60px;

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
  @media (min-width: 0px) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}
@mixin mobiletablet {
  @media (min-width: 0) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tabletdesktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tabletdesktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin safariprint {
  @media print and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0){ 
    @content;
  }
}
