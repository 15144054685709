.hero-img {
    /* Use "linear-gradient" to add a darken background effect to the image (photographer.jpg). This will make the text easier to read */
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    //     url("https://cdn.pixabay.com/photo/2015/07/29/00/08/photographer-865295_960_720.jpg");

    /* Set a specific height */
    height: calc(100vh - #{$header-height-mobile});
    @include mobile{
        max-height: -webkit-fill-available;
    } 
    
    
    width: 100%;

    /* Position and center the image to scale nicely on all screens */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
 
    & img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media print  {
            width: 100%;
            height: 300px;
        object-fit: cover;
           
         }
    }    
    
    @include desktop{
        height: calc(100vh - #{$header-height-tablet-desktop});
    }
    @media print  {        
        height: 300px;
     }

}



.hero-video {
    
    @include mobile{
        max-height: -webkit-fill-available;
    }
    position: relative;
    height: calc(100vh - #{$header-height-mobile});
    width: 100%;

    &.category-item-iframe-container {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        position: absolute;
        left: 0px;
    }


    .video-container {
        background: #eee;
        height: 100%;
        overflow: hidden;
        padding: 0;
        position: relative;

    }

    iframe {
        box-sizing: border-box;
        height: 56.25vw;
        left: 50%;
        min-height: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        width: 177.77777778vh;
    }
}

.hero {
    & .row--modifier{
        max-width: unset;
    }
    &__content {
        width: 68%;
        padding: 0.5rem 1rem;
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        color: $color-white;
        background-color: rgb(56,46,44,0.5);
        
        & a{
            color: $color-white;
        }

        &-header{
            font-size: $fontsize-large;
            @include desktop{
                font-size: $fontsize-h1;
            }
        }


        @include desktop {
            min-width: $hero-content-min-width;
            max-width: $hero-content-max-width;
            width: 50%;
        }

        @include tabletdesktop {
            width: unset;
            padding: rem(26px) rem(37px) rem(41px) rem(37px);
            @include center-x-y;
        }


        &--light {
            color: $color-white;
            & span {
                color: $color-white;
            }

            & a{
                color: $color-white;
            }
        }
    
    }

    &__products{
        @include tabletdesktop { 
            &--end-tag::after {
                content: "/";
            }
        }

        > span {
            display: block;

            @include tabletdesktop {
                display: inline-block;
            }
        }

        & a {
            display: block;

            @include tabletdesktop {
                display: inline-block;
            }
        }
    }

    //Block specific styling
    &.hero-block{

        & .hero__content{
            left: 50%;
            top: 43%;
            transform: translate(-50%, -50%);
            width: 78%;
            text-align: center;
            
            @include mobiletablet{
                padding-top: 1rem;
                padding-bottom: 1rem;
            }
            @include desktop{
                width: 80%;
                text-align: left;
            }
        }

    }

    // navlink block specific styling
    &.hero-block .hero__navlink{
        & .hero__content{
            width: unset;
            min-width: unset;
            @include desktop{
                padding-top: rem(41px);                           
            }
        }
        & .hero__nav-links{
            font-size: $fontsize-h4;
            line-height: 114%;
            display: block;            

            >ul{
               

                @include desktop{
                    display: flex;
                    flex-wrap: nowrap;
                    text-align: left;
                }

                & li{
                    font-size: $fontsize-large;
                    margin-bottom: 10px;
                }
                @include tabletdesktop{
                    
                }

                @include desktop {
                    :after {
                        content: "|";
                        padding-left: 1rem;
                        padding-right: 1rem;
                        display: inline-block;
                    }
        
                    :last-child:after {
                        content: none;
                        padding-right: 0;
                    }
        
                    & li {
                        margin-bottom: 0;
                        display: inline;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}


