.variant {
    font-size: $fontsize-xsmall;

    @include tabletdesktop {
        font-size: $fontsize-small;
    }

    & span {
        display: block;
    }
    .hide-print {
        @media print {
            display: none;
        }
    }

    > .row--modifier {
        @include tabletdesktop {
            border-bottom: 1px solid $color-black;
        }
    }

    &__category {
        color: $color-accent-1;
    }

    &__img {
        @include mobile {
            padding-left: 0;
            padding-right: rem(11px);
        }

        @include tabletdesktop {
            max-height: unset;
            height: $variant-tile-size;
            padding-bottom: 1rem;
        }

        //Size variants
        &--xxsmall {
            & img {
                @include tablet {
                    height: 25%;
                }
                @include desktop {
                    height: 12.5%;
                }
            }
        }

        &--xsmall {
            & img {
                @include tablet {
                    height: 30%;
                }
                @include desktop {
                    height: 21%;
                }
            }
        }

        &--small {
            & img {
                @include tablet {
                    height: 50%;
                }
                @include desktop {
                    height: 25%;
                }
            }
        }

        &--medium {
            & img {
                @include tablet {
                    height: 80%;
                }
                @include desktop {
                    height: 50%;
                }
            }
        }

        &--large {
            & img {
                @include tabletdesktop {
                    height: 100%;
                }
            }
        }

        & img {
            max-height: 100%;
            object-fit: contain;
            object-position: top;
        }
        &.variant_img--hover {
            &:hover {
                @include desktop {
                    & .variant__product-img {
                        opacity: 0;
                    }
                    & .variant__img-size {
                        opacity: 1;
                        transition: opacity 0.3s;
                    }
                }
            }
        }

        &-size {
            position: absolute;

            pointer-events: none;
            opacity: 0;
            height: 100%;
            width: 100%;

            background-repeat: no-repeat;
            background-position: bottom left;
        }

        & .bricmate-product {
            height: 100%;
            position: relative;

            &__click-container {
                height: 100%;
                display: flex;
                align-items: flex-end;
                cursor: pointer;
            }

            &:hover {
                & .expand-image__btn-container {
                    display: inline-block;
                }
            }
        }
    }

    &__info {
        line-height: 122%;

        display: flex;
        flex-direction: column;
        min-height: 160px;

        > div {
            flex: 1 1 auto;
        }

        @include mobile {
            padding-left: rem(16px);
            padding-right: 0;
        }

        > div:last-child {
            flex: 0 1 auto;
        }

        &-content-container {
            border-bottom: 1px solid $color-black;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            height: 100%;

            > div:first-child {
                padding-bottom: 1rem;
                flex: 0 1 auto;
            }

            @include tabletdesktop {
                border-bottom: unset;
                display: block;
            }
        }

        &-section--expand {
            flex: 1;

            .now-price {
                color: #FE5000;
            }
        }

        &-icon {
            @include icon;
            @include icon--expand;
        }
    }

    &__scale-chart {
        padding-top: 1.5rem;
        position: relative;
        padding-bottom: 0.5rem;
        padding-right: 1.6rem;

        & .variant__info-icon {
            right: 0;
            position: absolute;
            top: 65%;
            transform: translateY(-50%);
        }
    }
}

.productlistning__calculatedbox-label, .product__box-label{
    color: $text-dark-grey;
}

.product__box-error-label{
    color: red;
    font-size: 14px;

}