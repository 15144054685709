.btn{
    &--download{
        cursor: pointer;
        position: relative;
        padding-right: rem(50px);
        & .icon{
            display: block;
            position: absolute;
            right: -2px;
            top: 50%;
            transform: translateY(-50%);
            @include icon--medium;
            @include icon--download;
            
        }
        
    }
    &--print
    {
        cursor: pointer;
        position: relative;
        padding-right: rem(33px);
        & .icon-print{
            display: block;
            position: absolute;
            right: -2px;
            top: 50%;
            transform: translateY(-50%);
            @include icon--medium;
            @include icon--print;
            
        }
    }

    &__pill{
        padding: 6px 14px;
        border: 1px solid $color-black;
        border-radius: 1rem;
        font-weight: 400;
        text-transform: unset;
        color: $color-black;

        @include desktop{
            padding-left: 20px;
            padding-right: 20px;
        }


        &--selected{
            background-color: $color-accent-1;
            color: $color-white;
            border-color: $color-accent-1;
            box-shadow: 2px 3px 6px -4px $color-black;
            -webkit-box-shadow: 2px 3px 6px -4px black;
            -moz-box-shadow: 2px 3px 6px -4px black;
            text-shadow: 0.5px 0 0 $color-white;
        }
    }
}