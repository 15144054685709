.scale-chart {
    background-color: #ffff;
    z-index: 101;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0s 0.3s;

    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 103%;

    &.active {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s;
    }
    &__close-icon {
        @include icon;
        @include icon--close;
        @include center-x;
        bottom: 10%;
    }

    &__img {
        @include center-x-y;
        height: 60%;

        width: 100%;
        background-repeat: no-repeat;
        background-position: center;

    }
}

.expand-image {

    &__modal{
        position: fixed; /* Stay in place */
        z-index: 101; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
        display: none;


        &--open {
            display: block;
        }
    }

    &__modal-content {
        background-color: #fefefe;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        
        @include tabletdesktop{
            @include center-x;
            top: 7%;
            width: 80%;
            height: unset;
            min-height: 684px;
            border-radius: 0;
            max-width: 1200px;
        }

        @include desktop{
            overflow-y: auto;
        }

    }
    &__btn{
        &-container {
            display: inline-block;
            position: relative;            
            margin: 0.7rem 0;
            padding-right: 3rem;
            width: 100%;
            
            @include desktop{
                width: unset;
                display: none;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
    &__container {
        flex: 1 0 auto;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        max-width: 100%;

        > div{
            width: 90%;
            
            height: 100%;
            @include desktop{
                width: 70%;
            }
        }

        // carousel overrides
        & .carousel {
            max-width: unset;
            display: flex;
            flex-direction: column;
            height: 100%;

            &__current-element{
                flex: 1;
            }

            & .card__image.tile{
                height: unset;
            }

            &__action-bar{
                padding: 1rem 0 1rem;

                @include desktop{
                    padding: 1rem 0 2rem;  
                }

                & .arrow__btn {     
                    @include icon;
                    @include icon--next;
                    width: rem(14px);
                    height: rem(22px);

                    &:disabled {
                        filter: invert(79%) sepia(71%) saturate(32%) hue-rotate(37deg) brightness(116%) contrast(54%);
                    }

                    &--prev {
                        transform: rotate(180deg);
                    }                                 
                }
            }

            & .indicator{
                padding: 0 25px;
            }
            
            & .image-container{
                height: 450px;     
                display: flex;
                justify-content: center;          

                @include tablet{
                    height: 530px;
                }

                @include desktop{
                    height: 530px;
                }

                & .zoom-component{
                    width: 440px;
                    height: 440px;
                    max-width: calc(100vw - 10px);
                    border: 1px solid $background-light-grey;

                    @include desktop{
                        width: 530px;
                        height: 530px;                                
                        max-width: unset;
                    }
                }

                &::after{
                    content: none;
                }

                > div{
                    position: relative;
                }

                > img{
                    position: unset;
                    object-fit: contain;
                }
            }
        }
    }
    &__header {
        flex: 0 1 auto;
        font-size: 1rem;
        
        color: $color-accent-1;
        font-weight: 400;
        text-align: left;
        padding: 2rem 1rem;
        position: relative;
        @include desktop{
            font-size: rem(36px);
            padding: 2rem 1rem 1.4rem rem(58px);
        }
    }

    &__icon {
        position: absolute;
        right: 0;
        cursor: pointer;
        @include center-y;
        @include icon;
        @include icon--expand;
    }

    &__close-icon {
        flex: 0 1 auto;
        align-self: center;
        @include icon;
        @include icon--close-thin;
        margin: 1rem 1rem;

        &--desktop{
            right: rem(40px);
            width: 2rem;
            height: 2rem;
            @include center-y;
            @include icon;
            @include icon--close-thin;
            cursor: pointer;
        }
    }
}
