* {
    box-sizing: border-box;
}

body {
    background: $color-background;
    overflow: auto;
    position: relative;
    @media print{
        background-color: $color-white;
    }

    &.modal-open{
        overflow: hidden;
    }
}

// Handle ios focus outeline
// https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
    outline: none;
}

html {
    scroll-behavior: smooth;
    position: relative;

    //fix scroll bouncing for ios devices
    // https://stackoverflow.com/questions/29894997/prevent-ios-bounce-without-disabling-scroll-ability
    @include mobile {
        overflow: hidden;
    }
}

html,
body {
    height: 100%;

    @media print {
        height: initial !important;
        overflow: initial !important;
    }
    font-family: $font-family-body;
    line-height: 120%;
    color: $color-black;
    margin: auto;
}

#app {
    height: 100vh;
    margin: 0;
    display: flex;
    flex-direction: column;
}

main {
    margin-top: $header-height-mobile;
    margin-bottom: rem(44px);
    flex: 1 0 auto;

    @include tabletdesktop {
        margin-top: $header-height-tablet-desktop;
        margin-bottom: rem(81px);
    }

    & .block__container:not(:nth-child(1)) {
        margin: rem(15px) 0;

        @include tabletdesktop {
            margin: rem(30px) 0;
        }
    }
}

a {
}

h1,
.as-h1 {
    font-size: rem($fontsize-h1);
    line-height: 114%;
    font-weight: 400;
}

h2,
.as-h2 {
    font-size: rem($fontsize-h2);
    line-height: 122%;
    font-weight: 400;
}
h3,
.as-h3 {
    font-size: rem($fontsize-h3);
    line-height: 120%;
    font-weight: 400;
}

h4,
.as-h4 {
    font-size: rem($fontsize-h4);
    line-height: 118%;
    font-weight: 400;
}

h1,
.as-h1,
h2,
.as-h2,
h3,
.as-h3,
h4,
.as-h4,
h5,
h6 {
    margin: 0;
}

svg {
    height: 100%;
    width: 100%;
    display: block;
}

img {
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

.image-container {
    background-color: #fff;
    display: block;
    position: relative;
    width: 100%;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
        width: 100%;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0px;
    }
    &.contain {
        img {
            object-fit: contain;
        }
    }
}

a {
    text-decoration: none;
    color: $color-black;
    &:hover {
        text-decoration: underline;
    }
}

button {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
}

ul {
    padding-left: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    li {
        list-style: none;
    }
}

.text {
    &--right {
        text-align: right;
    }
    &--center {
        text-align: center;
    }
}

.bold {
    font-weight: 700;
}

.block__header {
    padding-bottom: 14px;
    border-bottom: 1px solid $color-black;

    @include mobiletablet {
        padding-bottom: rem(8px);
        font-size: rem(22px);
    }

    @include desktop {
        font-size: $fontsize-h4;
    }
}

.block__content {
    padding-top: $block-content-padding--mobile;

    @include desktop {
        padding-top: $block-content-padding--desktop;
    }
}

.block__top-row {
    @media print {
        padding-top: 0px;
    }
    padding-top: $block-padding--mobile;
    @include desktop {
        padding-top: $block-padding--desktop;
    }
    

}

.hide {
    display: none;
}

@include mobile {
    .hide-mobile {
        display: none !important;
    }
}

@include mobiletablet {
    .hide-mobiletablet {
        display: none !important;
    }
}

@include desktop {
    .hide-desktop {
        display: none !important;
    }
}

@include tablet {
    .hide-tablet {
        display: none !important;
    }
}

.full-height {
    @include full-height;
}

.search-icon {
    @include icon;
    @include icon--search;
}

.border-bottom
{
    @media print {
        border-bottom: 1px solid black;
    }
}