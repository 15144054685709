.menu {
    &__icon {
        @include icon;
        @include icon--menu;
        cursor: pointer;
    }

    &__wrapper {
        position: relative;
        transition: left 0.15s ease-in;
        background: #ebeae9;

        width: $meny-width;
        cursor: default;
        padding: 72px 20px 20px 20px;
        height: 100vh;
        overflow-y: auto;

        @include desktop {
            padding-top: rem(120px);
        }
    }

    &__close {
        &-icon {
            position: absolute;
            left: 24px;
            top: 24px;
            cursor: pointer;
            @include icon;
            @include icon--close;

            @include desktop {
                left: unset;
                right: 24px;
                top: 48px;
            }
        }
    }

    &__country-picker{
        position: absolute;
        top: 16px;
        right: 19px;

        & .country-picker{

            & .custom-dropdown{
                
                &__selected{
                    background-color: transparent;
                }

                &__list{
                    top: 56px;
                    left: -90px;
                    min-width: 205px;
                }

            }
        }
    }

    & .left-side {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -$meny-width;
        background: #ebeae9;
        z-index: 300;
        transition: left 0.15s ease-out;

        &.active {
            left: 0px;
            transition: left 0.15s ease-out;
        }
    }

    & a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        display: block;
    }

    .sub-menu {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out, padding-top 0.3s ease-out;

        font-weight: 400;

        &--show {
            max-height: 500px;
            transition: max-height 0.3s ease-in;
            padding-top: 10px;
        }
    }

}

.menu-link {
    position: relative;

    &__dropdown {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: rem(10px) 1.5rem;
        cursor: pointer;
        &-arrow {
            position: absolute;
            top: -1px;
            right: 10px;
            width: 21px;
            height: 21px;


            background-position-x: right;
            background-repeat: no-repeat;
            @include icon--arrow;

            &--open {
                transform: rotate(180deg);
            }
        }
    }

    &--active{
        font-weight: 700;
    }
}
