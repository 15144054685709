.rich-text{
    &.container{
        padding-top: 0;
    }
    @include mobile{
        padding-top: 0;

        & p{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}