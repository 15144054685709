.two-column-text{
    padding-top: 0;
    padding-bottom: 0;
}


//Remove padding for the second paragraph
.row--modifier [class^=col]{
    &.two-column-text__second-paragragh{
        @include mobile{
            padding-top: 0; 
        }
    }
}