.product-page {   
    & .container {
        padding-top: 0;
        padding-bottom: 0;
    }
    &__hero {
        & .hero-img {
            @include mobiletablet {
                height: unset;
                max-height: unset;
            }
            @include desktop {
                > .product-page__hero__img-container {
                    height: 100%;
                }
            }
            @include safariprint {
                height: unset;
                max-height: unset;

                > .product-page__hero__img-container {
                    height: unset;
                }
            }
        }

        & .hero-video {
            @include mobiletablet {
                max-height: unset;
            }
        }

        &-content-outer {
            display: flex;
            flex-direction: column;
        }

        &-content {
            width: unset;
            display: flex;
            flex-direction: column;
            @include mobiletablet {
                background-color: $color-white;
                color: $color-black;
                position: unset;
                transform: unset;
                padding: rem(4px) 1rem rem(62px) 1rem;

                & a {
                    color: $color-black;
                }
            }

            .content-inner {
                display: flex;
                flex-direction: column;

                @include desktop {
                    flex-direction: row;
                }
                .headername-container {
                    width: 100%;
                }
                .document-container {
                    width: 25%;
                }
            }

            .product-short-text {
                @include mobiletablet {
                    margin-top: 15px;
                }
            }

            .readmore-container {
                @include desktop {
                    display: none;
                }
            }

            @include desktop {
                min-width: $hero-content-min-width;
                max-width: $hero-content-max-width;
                width: 50%;
            }

            @media print {
                padding-bottom: 10px;
            }

            @include safariprint {
                position: unset;
                transform: unset;
                background-color: $color-white;
                color: $color-black;
                padding: 0.25rem 1rem 3.875rem 1rem;
                width: unset;
                max-width: unset;
            }

            & .block__header {
                @include mobiletablet {
                    padding-bottom: 21px;
                }
                @include desktop {
                    border-bottom-color: $color-white;
                }
            }

            & .product-page__hero-sub-header {
                font-size: $fontsize-small;
                line-height: 100%;
            }

            & p {
                margin-top: 0;
            }

            & .read-more-state {
                display: none;
            }

            & .read-more-wrap {
                @include desktop {
                    display: none;
                }
                height: auto;
                position: relative;
                @include mobiletablet {
                    height: 225px;
                    margin-bottom: 13px;
                }

                @media print {
                    height: unset;
                }

                overflow: hidden;
                transition: 0.3s ease-in-out;
            }

            & .read-more-wrap:after {
                @include desktop {
                    display: none;
                }
                @include mobiletablet {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100px;
                    bottom: 0;
                    left: 0;
                    background: -moz-linear-gradient(
                        top,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%
                    ); /* FF3.6-15 */
                    background: -webkit-linear-gradient(
                        top,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%
                    ); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(
                        to bottom,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 100%
                    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#FFFFFF',GradientType=0 ); /* IE6-9 */
                }

                @media print {
                    display: none;
                }
            }

            & .read-more-state:checked ~ .read-more-wrap {
                opacity: 1;
                font-size: inherit;
                height: 100%;
                transition: 0.3s ease-in-out;

                @include mobiletablet {
                    margin-bottom: 0;
                }

                & .read-more-wrap--no-margin-bottom {
                    & :last-child {
                        margin-bottom: 0;
                    }
                }

                &:after {
                    display: none;
                }
            }

            & .read-more-state ~ .read-more-trigger .show-more {
                display: block;
                @include desktop {
                    display: none;
                }
            }

            & .read-more-state ~ .read-more-trigger .show-less {
                display: none;
            }

            & .read-more-state:checked ~ .read-more-trigger .show-less {
                display: block;
            }

            & .read-more-state:checked ~ .read-more-trigger .show-more {
                display: none;
            }

            & .read-more-trigger {
                cursor: pointer;
            }
        }
    }

    &__action {
        &-container {
            display: flex;
            justify-content: flex-start;
            margin-top: rem(64px);
            @include desktop {
                justify-content: center;
                margin-top: unset;
            }

            @include mobiletablet {
                margin-top: rem(32px);
            }
            &--mobile {
                padding-left: rem(10px);
                margin-top: rem(30px);
                margin-bottom: rem(30px);
            }
            @include desktop {
                & .checkbox-star {
                    & .favourite-icon--star {
                        @include icon--star-white;
                    }

                    & .favourite-icon--star-filled {
                        @include icon--star-filled-white;
                    }
                }
            }
        }

        &-download {
            margin-bottom: 1rem;
            &--white {
                margin-bottom: 1rem;
                @include desktop {
                    & .icon {
                        @include icon--download-white;
                    }
                }
            }
        }
        &-print {
            margin-bottom: 1rem;
            &--white {
                @include desktop {
                    & .icon-print {
                        @include icon--print-white;
                    }
                }
            }
        }
    }

    &__information {
        &-container {
            // padding-top: rem(29px);
        }

        &-action-container {
            display: flex;
            justify-content: flex-start;
        }

        & p {
            margin-top: 0;
        }
    }

    &__action-tile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        @include tabletdesktop {
            border: 1px solid $color-black;
        }

        &[class^="col"] {
            padding: 0;
        }
    }

    &__find-in-store {
        display: flex;
        justify-content: center;
        padding-top: rem(34px);
    }

    &-tile {
        min-height: 200px;
        > .row {
            height: 100%;
        }
        @include tabletdesktop {
            min-height: 630px;
            min-width: 330px;
        }

        &--small {
            @include tabletdesktop {
                min-height: 450px;
            }

            & .variant__img {
                height: 160px;
                @include tabletdesktop {
                    height: 300px;
                }

                // Adapt variant images when the product-tiles are --small
                &--xxsmall {
                    & img {
                        height: 25%;
                        @include desktop {
                            height: 25%;
                        }
                    }
                }

                &--xsmall {
                    & img {
                        height: 40%;
                        @include desktop {
                            height: 40%;
                        }
                    }
                }

                &--small {
                    & img {
                        height: 50%;
                        @include desktop {
                            height: 50%;
                        }
                    }
                }

                &--medium {
                    & img {
                        height: 100%;
                        @include desktop {
                            height: 100%;
                        }
                    }
                }
            }

            & .variant__img-size {
                @include tabletdesktop {
                    height: 100%;
                }
            }

            & .additional-product__img {
                @include tabletdesktop {
                    height: 305px;
                }
            }
        }
    }

    .mood-board {
        .mood-board__image {
            img {
                @include mobile {
                    width: 100%;
                }
            }
        }
    }   
}
@media print {
    @page {
        size: A4;
        margin-top: 5px;
        margin-bottom: 0;
    }
    head {
        padding: 5px;
    }
    body {
        padding: 20px 10px 10px 10px;
    }

    .print-font-size {
        font-size: 12px;
        line-height: 110%;
    }
}
.threedfit-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 24px;
    @media (min-width: 768px) {margin-top: 46px;}
  }

  .threedfit-col-text {
    a {
        text-decoration: underline;
    }
    h3{
        font-size: 1.35rem;
        @media (max-width: 640px) {
            font-size: rem(19px);
        }
    }
    .threeDfit-bodytext {
        font-size: 1rem;
        @media (min-width:768px) and (max-width: 830px) {
            font-size: 0.95rem;
        }
    }
  }
  
  .threedfit-col-text,
  .threedfit-col-image {
    width: 100%;
  }

  
  
  @media (min-width: 768px) {
    .threedfit-col-text{
        padding-right: 46px;
      }
    .threedfit-col-text,
    .threedfit-col-image {
      width: 50%;
    }
  }
  
  .threedfit-col-image img {
    display: block;
    width: 100%;
    height: auto;
  }
  

.additional-product-container {
    margin-top: 30px;
}
.additional-product {
    display: flex;
    flex-direction: column;
    height: auto;
    > .row--modifier {
        @include tabletdesktop {
            border-bottom: 1px solid $color-black;
        }
    }

    .remove-padding{
        padding: 0;
    }

    &__img {
        display: flex;
        align-items: flex-start;
        position: relative;

        @include mobile {
            padding-left: 0;
            padding-right: rem(11px);
        }

        @include tabletdesktop {
            // max-height: unset;
            // height: 585px;
            padding-bottom: 1rem;
        }

        & img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &__info {
        &-container {
            position: relative;
            border-bottom: 1px solid black;

            a {
                line-height: 225%;
                margin-bottom: 15px;;
                margin-top: -15px;
            }
            
            @include mobile {
                padding-left: rem(5px);
                
            }

            @include tabletdesktop {
                padding-bottom: rem(24px);
                // min-height: rem(183px);

            }

            > div:first-child {
                padding-bottom: 1rem;
            }
        }

        &-last {
            position: absolute;
            bottom: rem(10px);
        }
    }
}

.document {
    padding-top: rem(64px);

    // a{
    //     text-decoration: underline;
    //     text-underline-offset: 4px;

    //     &:hover{
    //         font-weight: 600;
    //     }
    // }

    @media print {
        display: none;
    }

    &__container {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    &__download {
        margin-top: rem(10px);
    }

    &__environmental-markings-list {
        margin-top: rem(10px);
        display: flex;
        flex-direction: column;

        .environmental-markings-list-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 5px;

            .environmental-markings-list-title {
                margin-right: 5px;
                font-weight: 500;
                white-space: nowrap;
            }
            .environmental-markings-list-value {
                font-weight: 600;
            }
        }
    }
}
.project-overview__projects {
    @media print {
        display: none; 
    }

    .scroll-container {
        position: relative; 
    }
    
    .scroll-button {
        background-color: transparent; 
        border: none;
        cursor: pointer; 
        font-size: 24px; 
        z-index: 10;
        display: none;
    }

    @media (min-width: 1025px) {
        .scroll-button {
            display: block; // Show only on desktop
            position: absolute;
            top: 40%;
            height: 50px;
            width: 50px;
            border-radius: 50px;
            z-index: 999;
        }

        .scroll-button.left {
            left: -50px;
        }
        
        .scroll-button.right {
            right: -50px;
        }
    }

    .scrollable-content {
        display: flex; 
        flex-wrap: nowrap; 
        overflow-x: auto; 
        -webkit-overflow-scrolling: touch; 
        padding-bottom: 30px; 
        scrollbar-width: none; // Hide scrollbar on Firefox
        cursor: grab; // Indicate draggable on desktop
    }

    .scrollable-content:active {
        cursor: grabbing; // Change cursor when dragging
    }

    .scrollable-content::-webkit-scrollbar {
        height: 50px; 
        background-color: transparent; 
    }

    .scrollable-content::-webkit-scrollbar-thumb {
        background-color: $color-black; 
        border-radius: 10px;
    }

    .scrollable-content::-webkit-scrollbar-button {
        display: none !important; 
    }

    .scrollable-content::-moz-scrollbar {
        width: 40px; 
        height: 20px; 
    }

    .scrollable-content::-moz-scrollbar-thumb {
        background-color: $color-black; 
        border-radius: 10px; 
    }

    .scrollable-content:hover {
        scrollbar-color: $color-black transparent; 
    }

    .scrollable-item {
        flex: 0 0 calc(100% / 1.25); 
        box-sizing: border-box; 
        padding: 0 0.5rem; 

        @media (min-width: 640px) {
            flex: 0 0 calc(100% / 2.5); 
        }

        @media (min-width: 1024px) {
            flex: 0 0 calc(100% / 3.25); 
        }
    }

    .tile--portrait {
        width: 100%; 
    }

    @media (max-width: 1024px) {
        .scrollable-content {
            overflow-x: auto; 
            scrollbar-width: auto; 
            scrollbar-color: $color-black transparent;
        }

        .scrollable-content::-webkit-scrollbar {
            height: 20px; 
        }
    }

    @media (min-width: 1025px) {
        .scrollable-content {
            overflow-x: hidden; 
        }
    }
}

.inspiration {
    &__paragragh {
        &[class^="col"] {
            @include mobile {
                padding-top: 0;
            }
        }
    }
}

.hide-print {
    @media print {
        display: none;
    }
}
.col-print-3 {
    @media print {
        flex-basis: 33%;
        max-width: 33%;
    }
}
.col-print-4 {
    @media print {
        flex-basis: 25%;
        max-width: 25%;
    }
}
.col-print-12 {
    @media print {
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0px;
    }
}
.product-pdf-header {
    @media print {
        display: block;
        position: absolute;
        top: 15px;
        left: 25px;
    }
    display: none;
}
@media screen {
    div.footer-date {
        display: none;
    }
}
@media print {
    div.footer-date {
        position: fixed;
        bottom: 16px;
        right: 16px;
    }

    @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
        //Hide footer-date on safari v9+
        .footer-date {
            display: none;
        }
    }

    .list-print {
        page-break-inside: avoid;
        padding-top: 10px;
        &.row--modifier [class^="col"] {
            padding-top: 0;
        }

        //Safari fix
        & li {
            min-height: unset;
            min-width: unset;
        }
    }
}
@media print {
    .product-page__hero__img-container {
        padding: 0 1rem 0 1rem;
    }

    .print-friendly-variant {
        > .block__content {
            // padding-top: 10px;

            & .variant__info {
                border-bottom: none;
                display: block;
                padding-top: 5px;
                flex-basis: 0;
            }

            & .variant__img {
                height: 160px;
                flex-basis: 0;
                // Alternative solution
                // &--large{
                //     height: 320px;
                // }
                // &--small{
                //     flex-basis: 33%;
                // }

                // &--xxsmall{
                //     flex-basis: 40%;
                // }
            }
        }
    }

    .print-friendly__block-content {
        display: block;
        // alternative solution
        // display: flex;
        // flex-direction: column;
        // flex-wrap: nowrap;
        // justify-content: flex-end;
    }
}
