.custom-dropdown{
    position: relative;

    &__selected{
        padding: 14px 12px;
        background-color: $background-light-grey;     
        cursor: pointer;  
    }

    &:after{
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -3px;
        border-width: 6px 6px 0 6px;
        border-style: solid;
        border-color: $background-black transparent;
    }

   
    &__list{
        display: none;
        position: absolute;
        top: 44px;
        margin: 0;
        padding: 0;
        width: 100%;
        list-style: none;       
        background-color: $background-light-grey;
        z-index: 1;

        &-item{
            cursor: pointer;
            padding: 14px 12px;
            &:hover{
                background-color: $background-light-grey2;
            }
        }
    }

    &.active{
        & .custom-dropdown__list{
            display: block;
        }

        &:after{
            border-width: 0 6px 6px 6px;
        }
    }

}