.showrooms{
    &__list{
        display: grid;
        grid-gap: 1rem;
        gap: 1rem;  
               
        @include desktop{
            grid-template-rows: repeat(2, 1fr);
            grid-auto-columns: minmax(100px,200px);
            grid-auto-flow: column;
            grid-gap: 1.5rem;
            gap: 1.5rem;
        }

        //IE11 and IE10 specific
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            /* add your IE10-IE11 css here */   
            display: flex;
            flex-direction: row;

            &-item{
                margin-right:15px
            }
        }

        &-item{
            :first-child{
                font-weight: 700;
            }
        }
    }
} 