.category {
    & .container{
        padding-top: 0;
        padding-bottom: 0;

        &.category__hero-content--mobile {
            padding-top: 1rem;
            padding-bottom: 1rem;   
        }
    }
    &__hero{
        &-content{
            width: 68%;
            color: $color-white;
            @include center-x;
            top: 43%;
            transform: translate(-50%,-50%);
            display: flex;
            justify-content: center;            

            @include tablet{
                width: unset;
            }

            @include desktop{
                padding-top: rem(37px);
                width: unset;
                min-width: unset;
                max-width: unset;                           
            }
  
            &--mobile{
                background-color: $color-white;
                
                                
                >span{
                    & :first-child{
                        margin-top: 0;
                    }
    
                    & :last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }

    }

    

    &__products {
        font-size: $fontsize-small;

        @include desktop{
            font-size: $fontsize-normal;

        }

        &-listing{
            
            padding-top: rem(44px);

            & li:nth-child(n + 5){
                padding-top: rem(25px);
            }
            

            & .tile{
                padding-bottom: 0.7rem;

                & span{
                    line-height: 118%;
                }
            }
        }

        & [class^="col"] {
            @include mobile{
                padding: #{$column-padding} 4px 4px 0;
            }
        }

        & h4{
            font-weight: 400;
        }

    }

    &__sub-categories{

        text-align: center;

        @include desktop{
            text-align: left;
        }
        >ul{
            
            @include desktop{
                display: flex;
                flex-wrap: nowrap;
            }

            :after{                
                @include desktop{
                    content: "|";
                    padding-left: 1rem;
                    padding-right: 1rem;
                    display: inline-block;
                }
            }
            
            :last-child:after{
                @include desktop{
                    content: none;
                    padding-right: 0;
                }
            }
        }
        & li{
            font-size: $fontsize-large;
            line-height: 122%;
            padding-top: rem(5px);
            white-space: nowrap;
            margin-bottom: 10px;

            @include tablet{
                font-size: $fontsize-h4;
                line-height: 114%;
                padding-top: 0;
            }
            @include desktop{
                font-size: $fontsize-h4;
                line-height: 114%;
                display: inline;
                padding-top: 0;
                margin-bottom: 0px;
            }


            >a{
                color: $color-white;
            }
        }
    }

    &--no-root{
        & .category__hero-content{
            text-align: center;
            @include desktop{  
                text-align: left;       
                min-width: calc(#{$hero-content-min-width} - 100px);
                max-width: calc(#{$hero-content-max-width} - 100px);
                width: 50%;                
            } 
        }
    }
}
