.catalog-form{
    &.container{
        padding-top: 0;
        padding-bottom: 0;
    }

    & form{
        @include tabletdesktop{
            width: 50%;
        }
    }

    & button[type="submit"] {
        padding-top: 2.5rem;
        font-size: rem(14px);
        color: #000000;
    }

    &__input-fields{
        > .input-container{
            padding-top: 1.5rem;
        }
    }
}