.login {
  position: relative;
    display: flex;
    flex-flow: wrap;
    @media screen and (-ms-high-contrast: none) {
      margin-top: 90px;
      display: -ms-flexbox;
    }
    @include mobiletablet {
      @media screen and (-ms-high-contrast: none) {
        margin-top: 80px;
      }
    }
  &__intro {
    max-width: 470px;
    margin-left: 12.4%;
    padding-left: 7px;
    margin-top: calc(#{$line-margin} * 15);
    font-size: $font-size-menu;
    position: relative;
    @include mobiletablet {
      margin-top: 0;
      margin-left: 0;
      padding-left: 0;
      padding-right: 0;
      font-size: $font-size-menu-mob;
    }
    p {
      margin: 0;
    }
    a {
      color: $text-green;
      &:after {
        content: "\2192";
        font-family: $font-icon;
        margin-left: 3px;
      }
    }
   
  }
  form {
    width: 100%;
  }
  &__form-container {
    position: relative;
    margin-top: calc(#{$line-margin} * 4);
    border: 0;
    &:before {
      content: "";
      width: calc(50% - #{$column-gap});
      border-top: 1px solid $border-black;
      position: absolute;
      left: $column-margin;
      @media (max-width: 1400px) {
        width: calc(70% - #{$column-gap});
      }
      @include mobiletablet {
        display: none;
      }
    }
  }
  &__label {
    padding-top: $line-margin;
    padding-bottom: $line-margin;
    display: flex;
    flex-flow: row wrap;
    label {
      width: calc(50% - #{$column-margin});
      margin-right: $column-margin;
      @include mobiletablet {
        width: 100%;
        margin-right: 0;
      }
    }
    > label {
      @include mobiletablet {
        padding-bottom: $line-margin;
        border-bottom: 1px solid $border-black;
      }
    }
  }
  &__form-section {
    width: calc(50% - #{$column-margin});
    margin-left: $column-margin;
    @include mobiletablet {
      width: 100%;
      margin-left: 0;
    }
  }
  &__validator {
    margin-top: $line-margin;
  }
  .form__validator {
    color: $text-grey;
    &--error {
      color: $text-red;
    }
  }

  &__form {
   
    padding-bottom: $line-margin;
    width: 19vw;
    @include mobiletablet {
      width: 100%;
      max-width: 470px;
      padding-top: $line-margin;
    }

    .form-group {
      width: 100%;
      margin-bottom: $line-margin;
      input[type='email'], input[type='password'],input[type='text']{
        width: 100%;
        border: 0;
        border-bottom: 1px solid $border-black;
        padding-bottom: $line-margin;
        background-color: transparent;

        &:focus {
          outline: 0;
        }
      }
      &:nth-child(1) {
        @include mobiletablet {
          border-top: 0;
        }
 
      }
      &:nth-child(2) {
        border-bottom: 0;
      }
      label {
        display: block;
        //font-family: $font-bold;
      }
     
      .form__validator {
        font-size: 12px;
        &--error {
          font-size: 12px;
        }
      }
    }
  }
  &__button {
   
    padding-top: 2.5rem;
    font-size: 0.875rem;
    color: $text-black;
    white-space: nowrap;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }
  &--background {
    .login {
      &__button {
        background: $background-black;
        color: $text-white;
        position: absolute;
        right: $column-margin;
        top: 0;
        width: calc(30% + #{$column-margin});
      }
    }
  }
}
