.project-inspiration {

    & .container{
        padding-top: 0;
        padding-bottom: 0;
    }

    & .hero {
        &__content {
            padding: rem(29px) rem(36px) rem(50px) rem(36px);
            & p {
                margin-top: 0;
            }

            @include desktop {
                min-width: 30%;
            }
        }

        &-img {
            @include mobile {
                height: calc(50vh - #{$header-height-mobile});
            }
        }
    }

    &__hero {
        &-header-container {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 14px;
            border-bottom: 1px solid $color-white;

            > span {
                font-size: $fontsize-small;

                @include desktop {
                    font-size: $fontsize-normal;
                }
            }
        }

        & .block__header{
            border-color: $color-white;
        }

        &-product-list {
            display: inline;
            > li {
                display: inline;
                &:after {
                    content: "/";
                    padding: 0 2px;
                }

                &:last-child:after {
                    content: none;
                    padding-right: 0;
                }
            }
        }

        &-content-row {
            padding-bottom: rem(28px);
            @include desktop{
                padding-bottom: rem(57px);
            }
        }
    }

    &__mobile-hero-content{
        background-color: $color-white;
        padding: rem(12px) rem(16px) rem(40px) rem(16px);

        & p {
            margin-top: 0;
        }
    }
}


.products-in-project{
    padding-top: rem(44px);
    padding-bottom: 1rem;

    a:hover{
        text-decoration: none;
    }
    a span:hover{
        text-decoration: underline;
    }

    @include tabletdesktop{
        padding-top: rem(60px);
    }

    .category-name-tile {
        margin: 0;
        font-weight: 600;
    }
}