.checkbox-list{
    display: flex;
    grid-gap: 0.5rem 1rem;
    flex-wrap: wrap;

    &__item{
        display: none;
        
        @include desktop{
            margin-right: 9px;
        }
        
        >span{
            position: relative;
            padding-right: 1.3rem;
            font-size: $fontsize-xsmall;
            display: inline-block;

            @include desktop{
                font-size: 16px;
                padding-right: 1.4rem;
            }

            &:after{
                position: absolute;
                right: 0;
                top: 0.5px;                
                content: "";
                width: 12px;
                height: 12px;
                border: 1.5px solid $background-black;
            }
            
        }

        &--selected{
            display: block;

        }
    }


    &__checkmark{
        position: absolute;
        right: 4px;
        top: 3px;
        
        transform: rotate(45deg);
        border-bottom: 2px solid $color-accent-1;
        border-right: 2px solid $color-accent-1;


        display: inline-block;
        height: 9.5px;
        width: 6px;
    }

}

