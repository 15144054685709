.tile{
    
    border-bottom: 1px solid $color-black;
    padding-bottom: 1rem;
    height: 100%;
    & .image-container{
        margin-bottom: 10px;
    }

    & span {
        display: block;

    }

    &__container{
        color: $text-black;
        &:hover{
            text-decoration: none;
        }

    }
    
    &--portrait{
        & .image-container:after{
            //Calculated with the help of https://ratiobuddy.com/ Dimensions are taken from the sketches
            padding-bottom: $image-portrait;
        }
    }

    &--landscape{
        & .image-container:after{
            //Calculated with the help of https://ratiobuddy.com/ Dimensions are taken from the sketches
            padding-bottom: $image-landscape;
        }
    }

    &--square{
        & .image-container:after{
            //Calculated with the help of https://ratiobuddy.com/ Dimensions are taken from the sketches
            padding-bottom: $image-square;
        }
    }

    &--carousel{
        & .image-container:after{
            //Calculated with the help of https://ratiobuddy.com/ Dimensions are taken from the sketches
            padding-bottom: $image-carousel;
        }
    }

    &:hover{
        
        box-shadow: 0 2px 0px $color-black;
    }

}