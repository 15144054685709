.gmap {
  &__modal {
    position: fixed; /* Stay in place */
    z-index: 101; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(10, 7, 0, 0.6); /* Black w/ opacity */

    &--open {
      display: block;
    }

    &--close {
      display: none;
    }

    &-content {
      background-color: #fefefe;
      padding: rem(25px) rem(25px) rem(20px) rem(25px);
      border: 1px solid #888;
      width: rem(470px);
      z-index: 102;
      @include center-x-y;
    }

    &-close {
      @include icon;
      @include icon--close-thin;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    &-header {
      font-weight: 700;
      font-size: $fontsize-normal;
      padding-top: 1.7rem;
      border-bottom: 1px solid $color-black;
      padding-bottom: rem(5px);
    }

    &-address {
      padding-top: rem(7.5px);
      margin-bottom: rem(8.5px);
      font-size: $fontsize-normal;
    }

    &-website-address {
      color: $color-accent-1;
      font-weight: 600;
      font-size: $fontsize-normal;
      display: inline-block;
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }

    &-checkbox-list{
      margin-top: rem(30px);

      & .checkbox-list__checkmark{
        top: 2px
      }
    }
  }
}

.custom-marker {
  @include icon;
  @include icon--marker;
  width: 3.5rem;
  height: 3.5rem;
  //Color the svg
  filter: invert(35%) sepia(100%) saturate(2636%) hue-rotate(1deg) brightness(101%) contrast(105%);
}
