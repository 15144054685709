input[type="text"],
[type="email"],
[type="number"],
[type="tel"] {
    border: none;
    background-color: transparent;
    width: 94%;
    &:focus-visible {
        outline: unset;
    }
}

textarea[type="text"] {
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    resize: none;
    &:focus-visible {
        outline: unset;
    }
}

.text-area-container {
    height: 100%;

    & textarea {
        height: calc(100% - 41px);
    }

    & label {
        display: block;
        padding-top: 1.2rem;
        padding-bottom: 0.3125rem;
    }
}

button, .as-btn {
    white-space: nowrap;
    text-transform: uppercase;
    background-color: transparent;
    border: 0;
    font-weight: 600;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
    
}

.buy{
    font-size: $font-size-default-mob;
}

.input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid black;
    position: relative;
    padding-top: 1.2rem;
    padding-bottom: rem(5px);

    & label {
        position: relative;
        padding-right: rem(13px);
        white-space: nowrap;
    }

    &.required {
        & label:after {
            content: "*";
            color: $color-black;
            position: absolute;
            right: 0;
        }

        & label.error:after {
            color: $color-error;
        }
    }

    & .error {
        color: $color-error;
    }
}
