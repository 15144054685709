.country-picker{
    padding-right: 22px;

    &__country{
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 2px;
        display: block;
        cursor: default;
    }

    & .country{
        
    }

    &__sub-list{
        display: flex;
        flex-direction: row;

        &-item{
                        
            & span{
                font-size: 16px;
                cursor: pointer;
            }

            &--selected{
                &.country-picker__sub-list-item:hover{
                    text-decoration: none;                    
                }
                > a{
                    &:hover{
                        text-decoration: none;
                    }
                }
                & span{               
                    font-weight: 700;
                }
            }

            &:hover{
                text-decoration: underline;
            }

            &:not(:last-child){
                position: relative;
                padding-right: 14px;
                &::after{
                    content: '|';
                    width: 1px;
                    height: 1px;
                    position: absolute;
                    right: 9px;
                    top: 0;
                }                
            }
        }
    }
    
    //Custom styling for custom dropdown
    &.custom-dropdown{
        &:after{
            width: 9px;
            height: 9px;
            border: 1px solid #32557f;
            border-left: 0;
            border-top: 0;
            transform: rotate(45deg); 
            top: 41%;
            cursor: pointer;

        }

        &.active{
            &:after{
                transform: rotate(225deg);
                border-width: 1px !important;
                top: 51%;
            }
        }
    }

    & .custom-dropdown{  
        
        &__selected{
            background-color: $background-white;
            white-space: nowrap;
            text-decoration: underline;
        }
        
        &__list{
            background-color: $background-white;
            min-width: 216px;
            top: 68px;
            left: -26px;

            &-item{
                cursor: auto;
                padding-left: 38px;
                padding-right: 38px;

                &:hover{
                    background-color: $background-white;
                }
                &:last-child{
                    padding-bottom: 28px;
                }

                &:first-child{
                    padding-top: 18px;
                }
            }
        }
    }
}