.favourite{
    &-icon{
        display: inline-block;
        margin-right: 0.8rem;
        &--star{
            cursor: pointer;
            @include icon;
            @include icon--star;
        }
        &--star-filled{
            cursor: pointer;
            @include icon;
            @include icon--star-filled;
        }


    }

    &__container{
        position: relative;
    }

    &__counter{
        @include center-y;
        right: 0;
    }   

    &-tile__info{
        .btn--remove{
            cursor: pointer;
            font-weight: normal;
        }

        .bold--text{
            font-weight: 600;
        }
    }
}

.checkbox-star{
    .favourite-icon{
        position: absolute;
        right: 0;
        margin-right: -2px;
        width: 1rem;
        height: 1rem;
        top:0;
    }
}