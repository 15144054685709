.find-store {
    &__search {
        position: relative;
        border-bottom: 1px solid $color-black;
        margin-bottom: rem(80px);

        > form {
            padding: rem(8px) 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            //112px length of find my position btn
            width: calc(100% - 112px);

            & .pac-target-input {
                border: none;
                background-color: transparent;
                width: 94%;

                font-size: rem(18px);

                @include desktop {
                    line-height: 30px;
                    font-size: 30px;
                }
                &::placeholder {
                    font-size: rem(18px);
                    @include desktop {
                        font-size: 30px;
                        line-height: 33px;
                    }
                }
                &::-moz-placeholder {
                    font-size: rem(18px);
                    @include desktop {
                        font-size: 30px;
                        line-height: 33px;
                    }
                }
            }
        }

        & label {
            font-size: rem(18px);
            @include desktop {
                font-size: 30px;
                padding-right: 10px;
            }
        }
    }

    &__find-position {
        position: absolute;
        right: 0;
        font-weight: 700;
        text-transform: unset;
        @include center-y;
        color: $color-accent-1;
    }

    &__gmap-container {
        display: block;
    }

    &__filter{

        @include desktop{
            display: flex;
            align-items: center;
            margin-bottom: rem(48px);
            
        }

        &-header{
            font-weight: 600;

            @include desktop{
                font-weight: 400;
                margin-right: 1rem;
            }
        }

        &-list{
            display: flex;            
            margin-top: rem(12px);

            @include desktop{
                margin-top: 0;
            }

            & li:not(:last-child){
                margin-right: 1rem;
                @include desktop{
                    margin-right: rem(10px);
                }
            }
        }
    }

    & .store-list:first-of-type{
        margin-top: rem(24px);
    }
}

.store-list {
    margin-top: 2.4rem;
    
    &__header{
        border-top: 1px solid $color-black;
        color: $color-accent-1;
        padding-bottom: 1.375rem;
        font-weight: 700;
    }

    &__item {
        border-top: 1px solid $color-black;
        padding: rem(2px) 0 rem(5px) 0;

        >div{
            position: relative;
        }
    }

    &__item:last-child{
        border-bottom: 1px solid $color-black;
    }

    &__store {
        font-weight: 700;

        &-icon {
            @include icon;
            position: absolute;
            width: rem(40px);
            height: rem(40px);
        }

        &-marker-icon {
            @include icon--marker;
            @include center-y;
            right: 1.7rem;
            padding-left: 10px;
            //Color the svg
            filter: invert(35%) sepia(100%) saturate(2636%) hue-rotate(1deg) brightness(101%) contrast(105%);
        }

        &-link-icon {
            @include icon--link;
            @include center-y;
            right: 0;
        }
    }

    &__checkbox-list{
        margin-top: rem(14px);
        margin-right: 0.5rem;
        grid-gap: 0.5rem rem(24px);

        & .checkbox-list{
            &__checkmark{
                top: 2px;
            }
        }
    }
}
