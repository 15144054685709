.header {
    background-color: $color-white;
    position: fixed;
    width: 100%;
    top: 0;
    transition: top 0.3s;
    z-index: 100;

    > .container {
        height: $header-height-mobile;
        @include tabletdesktop {
            height: $header-height-tablet-desktop;
        }
        @include desktop {
            padding-top: 0;
        }
        
    }
    
    @media print  {
       display: none;     
    }

    &__sub-navigation {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @include tabletdesktop {
            :after {
                content: "|";
                padding-left: 1rem;
                padding-right: 1rem;
            }

            :last-child:after {
                content: none;
                padding-right: 0;
            }

            & li {
                display: inline;
            }
        }
    }

    &__icon {
        &-container {
            display: flex;
            height: 100%;
            align-items: center;
            flex-direction: row-reverse;

            @include tabletdesktop {
                flex-direction: row;
            }

            > :not(:first-child) {
                margin-right: 1rem;
                @include tabletdesktop {
                    margin-left: 1.5rem;
                    margin-right: unset;
                }

                &.header__country-picker{
                    margin-left: 0.5rem;
                }
            }
        }

        &-search {
            cursor: pointer;
            @include icon;
            @include icon--search;
        }

        &-favorite {
            @include icon;
            @include icon--ellipse;
        }
    }

    &__logos {
        display: flex;
        margin-left: auto;
    }

    &__logo{
        &-container{
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include mobiletablet {
                justify-content: flex-end;
            }

            @media only screen and (min-width: 1024px) and (orientation: portrait) {
                justify-content: left;
            }
        }

        left: 0;
        @include icon--logo;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 98% 100%;
        overflow: visible;
        width: 6rem;
        height: 2rem;
        bottom: 0;
        cursor: pointer;
        top: 0;
        margin-left: 3rem;
        margin-bottom: 0.5rem;
        margin-top: rem(5px);

        @include tabletdesktop {
            right: $column-padding;
            left: unset;
            
        }

        @media (max-width: $tablet-width) {
            position: absolute;
            margin: auto;
        }
    }

    &__cart{
        &-icon{
            
            @include icon;
            @include icon--cart;

            cursor: pointer;
            background-size: 25px;
            background-position: 0px;
            position: relative;
            top: 0px;
        }

        &-label{
            font-size: $font-size-default;
            font-family: $font-family-body;
            position: absolute;
            display: flex;
            align-items: center;
            left: 28px;
            top: 6px;
        }
    }

    & .row--modifier {
        height: 100%;
        flex-direction: row-reverse;

        @include tabletdesktop {
            flex-direction: row;
        }

        & [class^="col"] {
            padding-top: 0;
        }
    }

    &.scroll-up {
        top: 0;
    }

    &.scroll-down {
        top: -#{$header-height-mobile};
        @include tabletdesktop {
            top: -#{$header-height-tablet-desktop};
        }
    }
}
